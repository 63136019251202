import { ButtonColored, Paper, PaperBordered } from 'shared/ui';
import { LinearProgress } from '@mui/material';
import { useUserContext } from 'shared/hooks';

export const Step1 = ({ onClick }: { onClick: () => void }) => {
  const { username } = useUserContext();
  return (
    <Paper className="make-a-bet">
      <div className="paper__header">
        <LinearProgress variant="determinate" value={25} />
        <h2 className="paper__title">Подключить Steam</h2>
      </div>
      <div className="paper__body">
        <PaperBordered className="steam-account">
          <div className="steam-account__avatar">
            <img src="/avatar.png" alt="avatar" />
          </div>
          <div className="steam-account__info">
            <span className="steam-account__name">{username}</span>
          </div>
        </PaperBordered>
      </div>
      <div className="paper__footer">
        <ButtonColored className="max-w-full" text="Далее" onClick={onClick} />
      </div>
    </Paper>
  );
};