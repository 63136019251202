import { ButtonColored, ButtonDark, Paper } from 'shared/ui';
import { LinearProgress } from '@mui/material';
import type { MouseEventHandler } from 'react';
import { useRef, useState } from 'react';
import { postData } from 'shared/lib';
import { SITE_URL } from 'app/site-url';
import { useAlertContext, useCryptoContext } from 'shared/hooks';
import { InputMakeABet } from 'pages/make-a-bet/ui/inputs';
import { useNavigate } from 'react-router-dom';
import { RoutesPath } from 'shared/routes-path';

const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 12,
  width: 48,
  height: 48,
};

export const Step4 = ({ onClick }: { onClick: MouseEventHandler<HTMLButtonElement> }) => {
  const ref = useRef<HTMLInputElement>(null);

  const { currency, coefficient, usdRate } = useCryptoContext();
  const { onSuccess, onError } = useAlertContext();

  const navigate = useNavigate();

  const [total, setTotal] = useState(0);

  const onChange = (value: number) => {
    setTotal(value);
  };

  const onMakeBet = async () => {
    if (total < 0.0001) {
      onError('Минимальная ставка должна быть не менее 0.0001 ETH');

      return;
    }

    try {
      const response = await postData(
        SITE_URL + '/bet/',
        {
          'game_id': 1,
          price: total,
          coefficient,
          'is_multiplier': false,
        },
      );

      if (response.code === 201) {
        onSuccess('Ставка успешно создана!');

        setTimeout(() => {
          navigate(RoutesPath.CURRENT_BET);
        }, 1500);

        return;
      }

      onError(response.message);
    } catch (e) {
      console.log('error', e);
    }
  };

  return (
    <Paper className="make-a-bet">
      <div className="paper__header">
        <LinearProgress variant="determinate" value={75} />
        <h2 className="paper__title title">
          <span>Сделайте ставку</span>
          {/*<span className="title">*/}
          {/*    <span className="title__text">Посмотреть видео</span>*/}
          {/*    <span className="title__video-btn" />*/}
          {/*  </span>*/}
        </h2>
      </div>
      <div className="paper__body">
        <div className="coef">
          <div className="coef__block">
            <p className="coef__header">Мой коэффициент</p>
            <div className="coef__body body">
              <div>
                <span className="body__text-main">{coefficient}<sub>x</sub></span>
                <span className="body__text-sub">Стандартный</span>
              </div>
              {/*<ButtonColored text="Повысить коэффициент" style={{ width: 'fit-content' }} />*/}
            </div>
          </div>
          <div className="coef__block">
            <p className="coef__header">Сумма выигрыша</p>
            <div className="coef__body body">
              <div>
                <span
                  className="body__text-main">{!!total ? (+total * +coefficient).toFixed(4) : '--'}<sub>{currency}</sub></span>
                <span
                  className="body__text-sub">$ {!!total ? (+total * coefficient * usdRate).toFixed(2) : '--'} </span>
              </div>
            </div>
          </div>
        </div>
        <div className="bet-info">
          <InputMakeABet
            ref={ref}
            className="deposit__input-with-label"
            label="Сумма ставки"
            placeholder="--"
            // @ts-ignore
            onChange={onChange}
            usdRate={usdRate}
          />
        </div>
      </div>
      <div className="paper__footer" style={{
        display: 'flex',
        columnGap: 16,
      }}>
        <ButtonDark
          style={style}
          onClick={onClick}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5 12L5.25 12" stroke="white" strokeWidth="1.5" strokeLinecap="square"
                  strokeLinejoin="round" />
            <path d="M10.5 6L4.5 12L10.5 18" stroke="white" strokeWidth="1.5" strokeLinecap="square"
                  strokeLinejoin="round" />
          </svg>
        </ButtonDark>
        <ButtonColored text="Далее" className="max-w-full" onClick={onMakeBet} />
      </div>
    </Paper>
  );
};