import './styles.scss';
import { Fragment, useState } from 'react';
import { Step0, Step1, Step2, Step3, Step4 } from './ui/steps';
import { useUserContext } from 'shared/hooks';

export const MakeABetPage = () => {
  const { steam } = useUserContext()
  const [step, setStep] = useState(!!steam ? 4 : 0);

  const onClick = () => {
    setStep(prevState => prevState + 1);
  };

  return (
    <Fragment>
      {
        step === 0 && <Step0 onClick={onClick} />
      }
      {
        step === 1 && <Step1 onClick={onClick} />
      }
      {
        step === 2 && <Step2 onClick={onClick} />
      }
      {
        step === 3 && <Step3 onClick={onClick} />
      }
      {
        step === 4 && (
          <Step4 onClick={() => {
            setStep(prevState => prevState - 1);
          }} />
        )
      }
    </Fragment>
  );
};