import { CryptoContext } from 'shared/hooks';
import type { FunctionComponent } from 'react';
import { sepolia } from 'wagmi/chains';
import { useQuery } from '@tanstack/react-query';
import { Spinner } from 'shared/ui';

export const withCrypto = (Component: FunctionComponent) => () => {
  const { data } = useQuery<{ 'ethereum': { 'usd': number } }>({
    queryKey: ['ethPrice'],
    queryFn: () => fetch(`https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd`).then((res) => res.json()),
    staleTime: Infinity,
  });

  if (!data) return <Spinner />;

  return (
    <CryptoContext.Provider value={{
      chainId: sepolia.id,
      currency: 'ETH',
      usdRate: data.ethereum.usd,
      coefficient: 1.5,
    }}>
      <Component />
    </CryptoContext.Provider>

  );
};
