import { ButtonColored, Paper } from 'shared/ui';
import { LinearProgress } from '@mui/material';
import { SITE_URL } from 'app/site-url';
import { useQueryClient } from '@tanstack/react-query';
import { useAlertContext, useAuthContext, useUserContext } from 'shared/hooks';

export const Step0 = ({ onClick }: { onClick: () => void }) => {
  const client = useQueryClient();
  const { isAuth } = useAuthContext();
  const { onError } = useAlertContext();
  const { steam } = useUserContext();

  const _onClick = async () => {
    // @ts-ignore
    await client.refetchQueries(['user', isAuth]);
    !!steam && onClick();
    !steam && onError('Похоже вы не вошли в Steam, попробуйте еще раз и нажмите Далее');
  };

  return (
    <Paper className="make-a-bet">
      <div className="paper__header">
        <LinearProgress variant="determinate" value={25} />
        <h2 className="paper__title">Подключить Steam</h2>
      </div>
      <div className="paper__body flex items-center justify-center">
        <a className="connect-steam bg-[#FFFFFF14] hover:bg-[#FFFFFF14]/10"
           target="_blank"
           href={SITE_URL + '/steam/login/'}>
          <span className="connect-steam__logo relative">
            <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_903_12001)">
                <path
                  d="M24.179 0.644531C11.6461 0.644531 1.37896 10.3082 0.40332 22.5892L13.1907 27.8762C14.2742 27.1351 15.5832 26.701 16.9914 26.701C17.1176 26.701 17.2432 26.7053 17.3678 26.7123L23.0545 18.4698C23.0545 18.4304 23.0539 18.3921 23.0539 18.3533C23.0539 13.392 27.0897 9.35572 32.0515 9.35572C37.0128 9.35572 41.0485 13.392 41.0485 18.3533C41.0485 23.3146 37.0128 27.3514 32.0515 27.3514C31.983 27.3514 31.9151 27.3498 31.8471 27.3482L23.7367 33.1351C23.741 33.2403 23.7448 33.3476 23.7448 33.4544C23.7448 37.179 20.7154 40.2079 16.9914 40.2079C13.7225 40.2079 10.9892 37.8747 10.3701 34.7849L1.22525 31.0043C4.05671 41.0185 13.2571 48.3603 24.179 48.3603C37.3558 48.3603 48.0371 37.6784 48.0371 24.5032C48.0371 11.3259 37.3552 0.644531 24.179 0.644531Z"
                  fill="#DFDFDF" />
                <path
                  d="M15.354 36.8452L12.4233 35.6344C12.9427 36.7157 13.8412 37.6213 15.0342 38.1185C17.6133 39.1929 20.5866 37.9691 21.6615 35.3879C22.1819 34.1399 22.1851 32.7619 21.669 31.5107C21.1539 30.2589 20.1821 29.2827 18.9325 28.7618C17.6926 28.2456 16.3642 28.2645 15.1971 28.7051L18.2243 29.9569C20.1265 30.7497 21.0261 32.934 20.2333 34.8362C19.4421 36.7389 17.2562 37.6385 15.354 36.8452Z"
                  fill="#FFEEE2" />
                <path
                  d="M38.0462 18.3533C38.0462 15.0478 35.3571 12.3582 32.0511 12.3582C28.7455 12.3582 26.0559 15.0478 26.0559 18.3533C26.0559 21.6594 28.7455 24.3479 32.0511 24.3479C35.3571 24.3474 38.0462 21.6588 38.0462 18.3533ZM27.5574 18.3431C27.5574 15.8557 29.5739 13.8397 32.0613 13.8397C34.5487 13.8397 36.5652 15.8557 36.5652 18.3431C36.5652 20.8304 34.5487 22.8464 32.0613 22.8464C29.5739 22.8464 27.5574 20.8299 27.5574 18.3431Z"
                  fill="#FFEEE2" />
              </g>
              <defs>
                <clipPath id="clip0_903_12001">
                  <rect width="47.4607" height="48" fill="white" transform="translate(0.5 0.5)" />
                </clipPath>
              </defs>
            </svg>
          </span>
          <span className="connect-steam__text">
            Подключить steam
          </span>
        </a>

        {/*<a href={SITE_URL + '/steam/login/'}></a>*/}
      </div>
      <div className="paper__footer">
        <ButtonColored className="max-w-full" text="Далее" onClick={_onClick} />
      </div>
    </Paper>
  );
};