import { ButtonColored, Paper } from 'shared/ui';
import { LinearProgress } from '@mui/material';
import { UserWallets } from 'widgets/user-wallets';
import { useEffect, useRef } from 'react';
import { useAlertContext, useCryptoContext, useUserContext } from 'shared/hooks';
import { InputWithCurrency } from '../inputs';
import { useSendTransaction } from 'wagmi';
import { parseEther } from 'viem';
import { postData } from 'shared/lib';
import { SITE_URL } from 'app/site-url';

const to = process.env.REACT_APP_SERVICE_WALLET! as `0x${string}`;

// @ts-ignore
export const Step3 = (props) => {
  const ref = useRef<HTMLInputElement>(null);

  const { currency } = useCryptoContext();
  const { balance } = useUserContext();

  const { onSuccess } = useAlertContext();

  const { data: hash, isPending, sendTransaction } = useSendTransaction();

  useEffect(() => {
    hash && onPaymentSuccess(hash);
  }, [hash]);

  const onClick = () => {
    if (balance > 0) {
      props.onClick();
    }

    if (ref.current) {
      if (ref.current.value) {
        sendTransaction({ to, value: parseEther(ref.current.value) });
        return;
      }
      ref.current.focus();
    }
  };

  const onPaymentSuccess = async (hash: `0x${string}`) => {
    const response = hash && await postData(`${SITE_URL}/payment/`, { hash_transaction: hash });

    if (response.code !== 200 || response.code !== 201 || response.code !== 204) {
      onSuccess('Транзакция успешна');
      setTimeout(() => {
        props.onClick();
      }, 2000);
    }
  };

  return (
    <Paper className="make-a-bet">
      <div className="paper__header">
        <LinearProgress variant="determinate" value={75} />
        <h2 className="paper__title title">
          <span>Пополнить баланс</span>
          {/*<span className="title">*/}
          {/*    <span className="title__text">Посмотреть видео</span>*/}
          {/*    <span className="title__video-btn" />*/}
          {/*</span>*/}
        </h2>
      </div>
      <div className="paper__body">
        <div className="deposit">
          <div className="deposit__wallet">
            <h2 className="deposit__title">Кошелек</h2>
            <div className="deposit__wallets">
              <UserWallets showAddButton />
            </div>
          </div>
          <div className="deposit__balance">
            <h2 className="deposit__title">Депозитный баланс</h2>
            <InputWithCurrency
              ref={ref}
              className="deposit__input-with-label"
              label="Сумма пополнения"
              placeholder="--"
              currency={currency}
              max={balance}
            />
          </div>
        </div>
      </div>
      <div className="paper__footer">
        <ButtonColored className="max-w-full" text={balance > 0 ? 'Далее' : 'Пополнить баланс'} onClick={onClick} disabled={isPending} />
      </div>
    </Paper>
  );
};