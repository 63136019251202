import './styles.scss';
import { useCryptoContext, useUserContext } from 'shared/hooks';
import { Link } from 'react-router-dom';

export const SidebarBalance = ({ isSmallSidebar }: { isSmallSidebar: boolean }) => {
  const { balance } = useUserContext();
  const { usdRate, currency } = useCryptoContext();

  return (
    <Link to={'/payments'} className={`sidebar__balance balance ${isSmallSidebar ? 'balance_small' : ''}`}>
      <img className="balance__small-icon" src="/balance.svg" alt="balance" />
      <div className="balance__normal">
        <div className="balance__title">Баланс</div>
        <div className="balance__info">
        <span className="balance__crypto">
          <span>{balance}</span>
          <span>{currency}</span>
        </span>
          <span className="balance__total">${(usdRate * balance).toFixed(4)}</span>
        </div>
      </div>
    </Link>
  );
};