import './styles.scss';
import { ButtonDark, Paper } from 'shared/ui';
import { useAuthContext, useUserContext } from 'shared/hooks';
import { Fragment, useState } from 'react';
import { ProfileTabs } from './ui/tabs';
import { UserWallets } from 'widgets';
import { SITE_URL } from 'app/site-url';
import { useMutation } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { RoutesPath } from 'shared/routes-path';
import { useNavigate } from 'react-router-dom';

export const ProfilePage = () => {
  const { username, email, id } = useUserContext();
  const { setIsAuth } = useAuthContext();
  const navigate = useNavigate();

  const [isEditMode, setIsEditMode] = useState(false);

  const onSettingsButtonClick = () => {
    setIsEditMode(prevState => !prevState);
  };

  const logOutMutation = useMutation({
    mutationFn: () => fetch(SITE_URL + '/logout/'),
    onSuccess: () => {
      setIsAuth(true);
      Cookies.remove('isAuth');
      navigate(RoutesPath.AUTH);
    },
  });

  return (
    <Fragment>
      {
        !isEditMode
          ? (
            <Paper className="profile">
              <div className="paper__header">
                <h2 className="paper__title">Привет, {username}</h2>
                <span className="profile__user-id">ID{id}</span>
                <button className="profile__user-settings" onClick={onSettingsButtonClick}>
                  {/*<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                  {/*  <path*/}
                  {/*    d="M20.625 16.4345V7.56574C20.6243 7.43222 20.5884 7.30125 20.5209 7.18603C20.4534 7.07081 20.3567 6.97543 20.2406 6.90949L12.3656 2.45637C12.2545 2.39219 12.1284 2.3584 12 2.3584C11.8716 2.3584 11.7455 2.39219 11.6344 2.45637L3.75937 6.90949C3.64327 6.97543 3.5466 7.07081 3.47911 7.18603C3.41163 7.30125 3.37572 7.43222 3.375 7.56574V16.4345C3.37572 16.568 3.41163 16.699 3.47911 16.8142C3.5466 16.9294 3.64327 17.0248 3.75937 17.0907L11.6344 21.5439C11.7455 21.608 11.8716 21.6418 12 21.6418C12.1284 21.6418 12.2545 21.608 12.3656 21.5439L20.2406 17.0907C20.3567 17.0248 20.4534 16.9294 20.5209 16.8142C20.5884 16.699 20.6243 16.568 20.625 16.4345V16.4345Z"*/}
                  {/*    stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />*/}
                  {/*  <path*/}
                  {/*    d="M12 15.375C13.864 15.375 15.375 13.864 15.375 12C15.375 10.136 13.864 8.625 12 8.625C10.136 8.625 8.625 10.136 8.625 12C8.625 13.864 10.136 15.375 12 15.375Z"*/}
                  {/*    stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />*/}
                  {/*</svg>*/}
                  <span>Редактировать</span>
                </button>
              </div>
              <div className="paper__body">
                <div className="user-info">
                  <div className="user-info__block">
                    <p className="user-info__header">Email</p>
                    <div className="user-info__body body">
                      <span className="body__text-main">{email}</span>
                    </div>
                  </div>
                  <div className="user-info__block">
                    <p className="user-info__header">Телефон</p>
                    <div className="user-info__body body">
                      <span className="body__text-main">+1 (942) 942 - 1391</span>
                    </div>
                  </div>
                </div>
                <div className="user-wallets">
                  <p className="user-wallets__heading">кошелек</p>
                  <div className="user-wallets__wallets">
                    <UserWallets showAddButton={false} />
                  </div>
                </div>
              </div>
              <div className="paper__footer">
                <ButtonDark className="max-w-full" text="Выйти" disabled={logOutMutation.isPending} onClick={() => logOutMutation.mutate()} />
              </div>
            </Paper>
          )
          : (
            <Paper className="profile">
              <div className="paper__header">
                <h2 className="paper__title">Настройки профиля</h2>
                <span className="profile__user-id">ID{id}</span>
                <button className="profile__user-settings" onClick={onSettingsButtonClick}>
                  {/*<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                  {/*  <path*/}
                  {/*    d="M20.625 16.4345V7.56574C20.6243 7.43222 20.5884 7.30125 20.5209 7.18603C20.4534 7.07081 20.3567 6.97543 20.2406 6.90949L12.3656 2.45637C12.2545 2.39219 12.1284 2.3584 12 2.3584C11.8716 2.3584 11.7455 2.39219 11.6344 2.45637L3.75937 6.90949C3.64327 6.97543 3.5466 7.07081 3.47911 7.18603C3.41163 7.30125 3.37572 7.43222 3.375 7.56574V16.4345C3.37572 16.568 3.41163 16.699 3.47911 16.8142C3.5466 16.9294 3.64327 17.0248 3.75937 17.0907L11.6344 21.5439C11.7455 21.608 11.8716 21.6418 12 21.6418C12.1284 21.6418 12.2545 21.608 12.3656 21.5439L20.2406 17.0907C20.3567 17.0248 20.4534 16.9294 20.5209 16.8142C20.5884 16.699 20.6243 16.568 20.625 16.4345V16.4345Z"*/}
                  {/*    stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />*/}
                  {/*  <path*/}
                  {/*    d="M12 15.375C13.864 15.375 15.375 13.864 15.375 12C15.375 10.136 13.864 8.625 12 8.625C10.136 8.625 8.625 10.136 8.625 12C8.625 13.864 10.136 15.375 12 15.375Z"*/}
                  {/*    stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />*/}
                  {/*</svg>*/}
                  <span>Редактировать</span>
                </button>
              </div>
              <div className="paper__body">
                <ProfileTabs onBack={onSettingsButtonClick} />
              </div>
            </Paper>
          )
      }
    </Fragment>
  );
};