import { ButtonClose, ButtonColored, ButtonDark, InputWithLabel } from 'shared/ui';
import { cx } from '@emotion/css';
import type { DispatchedStateAction } from 'shared/typings';
import './styles.scss';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useAccount } from 'wagmi';
import { useCryptoContext, useUserContext } from 'shared/hooks';

type WithdrawDialogProps = {
  isOpen: boolean,
  setIsOpen: DispatchedStateAction<boolean>
}


export const WithdrawDialog = ({
                                 isOpen,
                                 setIsOpen,
                               }: WithdrawDialogProps) => {
  const { usdRate, currency } = useCryptoContext();
  const { balance } = useUserContext();

  const onClick = () => {
    setIsOpen(false);
  };

  const schema = yup.object({
    amount: yup.number().max(balance).required(),
    wallet: yup.string().trim().min(8).required(),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const { address } = useAccount();

  const onSubmit = (data: any) => {
    console.log(data);
  };

  return (
    <div className={cx('withdraw-dialog__wrapper', { open: isOpen })} onClick={onClick}>
      <div className="withdraw-dialog__bg" />
      <div className="withdraw-dialog" onClick={(event) => event.stopPropagation()}>
        <div className="withdraw-dialog__header">
          <ButtonClose onClick={() => setIsOpen(false)} />
          <p className="withdraw-dialog__text">Вывод средств</p>
          <div className="withdraw-dialog__confirm-code">
            Обработка запроса и отправка денег может <br />
            занять до 36 часов.
          </div>
        </div>
        <div className="withdraw-dialog__body">
          <div className="withdraw-dialog__balance balance">
            <div className="balance__title">Баланс</div>
            <div className="balance__info">
                <span className="balance__crypto">
                  <span>{balance}</span>
                  <span>{currency}</span>
                </span>
              <span className="balance__total">${(usdRate * balance).toFixed(4)}</span>
            </div>
          </div>
          <InputWithLabel
            {...register('amount')}
            label="Сумма"
            placeholder="Введите данные"
          />
          {errors && errors['amount'] && <span style={{ color: 'red' }}>Сумма должна быть меньше или равна {balance}</span>}
          <InputWithLabel
            disabled
            {...register('wallet', {
              value: address,
            })}
            label="Кошелек"
            placeholder="Введите данные"
          />
        </div>
        <div className="withdraw-dialog__actions actions">
          <ButtonColored text="Подтвердить" onClick={handleSubmit(onSubmit)} />
          <ButtonDark text="Отменить" onClick={onClick} />
        </div>
      </div>
    </div>
  );
};