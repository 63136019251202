import './styles.scss';
import { BetItem, Paper, Spinner } from 'shared/ui';
import { useQuery } from '@tanstack/react-query';
import { SITE_URL } from 'app/site-url';
import { Fragment } from 'react';
import type { Bet } from 'typings/bet';

export const CurrentBetPage = () => {
  const { data } = useQuery<{ data: [Bet] }>({
    queryKey: ['current-bet'],
    queryFn: () => fetch(`${SITE_URL}/bet/`).then((res) => res.json()),
  });

  return (
    <Paper className="current-bet">
      {!data
        ? <Spinner />
        : (
          <Fragment>
            <div className="paper__header">
              <h2 className="paper__title title">
                <span>Текущая ставка</span>
              </h2>
            </div>
            <div className="paper__body">
              <BetItem {...data.data[0]} />
            </div>
          </Fragment>
        )}
    </Paper>
  );
};