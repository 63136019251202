import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import type { FormEvent, HTMLAttributes, ReactNode, SyntheticEvent } from 'react';
import { useState } from 'react';
import './styles.scss';
import { ButtonColored, ButtonDark, InputWithLabel } from 'shared/ui';
import { useAlertContext, useUserContext } from 'shared/hooks';
import { UserWallets } from 'widgets';
import { putData } from 'shared/lib';
import { SITE_URL } from 'app/site-url';

type TabPanelProps = {
  children?: ReactNode;
  index: number;
  value: number;
} & HTMLAttributes<HTMLDivElement>

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export const ProfileTabs = ({ onBack }: { onBack: () => void }) => {
  const { email, username } = useUserContext();
  const [value, setValue] = useState(0);
  const { onError, onSuccess} = useAlertContext();

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // @ts-ignore
    const { username, email, password, newPassword, confirm } = (event.target as HTMLFormElement).elements;

    if (!password.value) {
      onError('введите старый пароль');

      return false;
    }

    if (!newPassword.value || !confirm.value) {
      onError('пароли не совпадают');

      return false;
    }

    if (newPassword.value !== confirm.value) {
      onError('пароли не совпадают');

      return false;
    }

    try {
      const response = await putData(
        SITE_URL + '/password/change/',
        {
          new_password: newPassword.value,
          old_password: password.value,
        },
      );

      if (response.code !== 200) {
        onError(response.message);
        return;
      }

      onSuccess('Пароль успешно изменен');

    } catch (e) {
      console.log('error', e);
      onError('что-то пошло не так');
    }
  };

  return (
    <div className="profile__tabs tabs">
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Личные данные" {...a11yProps(0)} />
        <Tab label="Мои кошельки" {...a11yProps(1)} />
        <Tab label="Пароль" {...a11yProps(2)} />
      </Tabs>
      <TabPanel className="tabs__panel" value={value} index={0}>
        <div className="tabs__content">
          <InputWithLabel id="username" label="Ваш ник" placeholder="Введите данные" defaultValue={username} />
          <InputWithLabel id="email" label="E-mail" type="email" placeholder="Введите данные" defaultValue={email} />
        </div>
        <div className="tabs__actions">
          <ButtonDark text={'Отмена'} onClick={onBack} />
          <ButtonColored text={'Сохранить изменения'} />
        </div>
      </TabPanel>
      <TabPanel className="tabs__panel" value={value} index={1}>
        <div className="tabs__content">
          <UserWallets showAddButton />
        </div>
      </TabPanel>
      <form onSubmit={onSubmit}>
        <TabPanel className="tabs__panel" value={value} index={2}>
          <div className="tabs__content">
            <InputWithLabel id="password" label="Старый пароль" placeholder="Введите данные" />
            <InputWithLabel id="newPassword" label="Новый пароль" type="password" placeholder="Введите данные" />
            <InputWithLabel id="confirm" label="Повторите новый пароль" type="password" placeholder="Введите данные" />
          </div>
          <div className="tabs__actions">
            <ButtonDark text={'Отмена'} onClick={onBack} />
            <ButtonColored text={'Сохранить изменения'} />
          </div>
        </TabPanel>
      </form>
    </div>
  );
};