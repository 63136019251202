import './styles.scss';
import { BetItem, Paper, Spinner } from 'shared/ui';
import { useState } from 'react';
import { cx } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';
import type { Bet } from 'typings/bet';
import { SITE_URL } from 'app/site-url';

type BetAll = {
  code: number
  count: number
  next: number | null
  previous: number | null
  results: Bet[]
}

const AllBets = () => {
  const { data } = useQuery<BetAll>({
      queryKey: ['other-bets'],
      queryFn: () => fetch(`${SITE_URL}/bet/all/?is_active=false`).then((res) => res.json()),
    },
  );

  if (!data) {
    return <Spinner />;
  }

  return (
    Array.isArray(data.results) && data.results.length
      ? data.results.map((bet) => <BetItem key={bet.id} {...bet} />)
      : <p className="other-bets__no-bet">Здесь будут отображаться все ставки пользователей</p>
  );
};

const CurrentBet = () => {
  const { data } = useQuery<BetAll>({
    queryKey: ['bet'],
    queryFn: () => fetch(`${SITE_URL}/bet/all/?is_active=true`).then((res) => res.json()),
  });

  if (!data) {
    return <Spinner />;
  }

  return (
    Array.isArray(data.results) && data.results.length
      ? data.results.map((bet) => <BetItem key={bet.id} {...bet} />)
      : <p className="other-bets__no-bet">Здесь будут отображаться текущие ставки пользователей</p>
  );
};

export const OtherBetsPage = () => {
  const [isActive, setIsActive] = useState(false);

  const onClick = () => {
    setIsActive(!isActive);
  };

  return (
    <Paper className="other-bets">
      <div className="paper__header">
        <h2 className="paper__title title">
          <span>Чужие ставки</span>
        </h2>
      </div>
      <div className="paper__body">
        <div className="tabs">
          <div className="tabs__header">
              <span
                className={cx('tab', { active: !isActive })}
                onClick={onClick}
              >
                Текущие
              </span>
            <span
              className={cx('tab', { active: isActive })}
              onClick={onClick}
            >
                История
              </span>
          </div>
          <div className="tabs__body">
            {
              isActive
                ? <AllBets />
                : <CurrentBet />
            }
          </div>
        </div>
      </div>
    </Paper>
  );
};