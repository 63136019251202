import type { SelectChangeEvent } from '@mui/material';
import { MenuItem, Select } from '@mui/material';
import { cx } from '@emotion/css';
import { forwardRef, useState } from 'react';
import type { ChangeEvent } from 'react';
import type { InputProps } from 'shared/ui/input';

export const InputMakeABet = forwardRef<HTMLInputElement, InputProps & { usdRate: number }>((props, ref) => {
  const [age, setAge] = useState('ETH');
  const [total, setTotal] = useState(0);

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    // @ts-expect-error
    ref.current.value = event.target.value;

    // @ts-expect-error
    setTotal(ref.current.value * props.usdRate);

    // @ts-expect-error
    props.onChange(ref.current.value);
  };

  return (
    <div className={cx(
      'input-with-label input-make-a-bet',
      props.className,
      { disabled: props.disabled })
    }>
      <label htmlFor={props.id}>{props.label}</label>
      <div>
        <Select
          labelId="provider-currency-select-label"
          id="provider-currency-select"
          value={age}
          onChange={handleChange}
        >
          <MenuItem value={'ETH'}>
            <IconETH />
          </MenuItem>
        </Select>
        <input
          // @ts-ignore
          onChange={onChange}
          ref={ref}
          id={props.id}
          type="number"
          placeholder={props.placeholder} required
          defaultValue={props.defaultValue}
          min="0.0001"
          step="0.0001"
          disabled={props.disabled}
        />
        <span>$ {total.toFixed(2)}</span>
      </div>
    </div>
  );
});

InputMakeABet.displayName = 'InputMakeABet';

const IconETH = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_924_2216)">
        <path
          d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12Z"
          fill="white" />
        <path d="M11.9985 4.5L11.8838 4.84183V14.76L11.9985 14.8604L17.247 12.1391L11.9985 4.5Z"
              fill="#343434" />
        <path d="M11.9986 4.5L6.75 12.1391L11.9986 14.8604V10.0464V4.5Z" fill="#8C8C8C" />
        <path d="M11.9983 15.7323L11.9336 15.8015V19.3345L11.9983 19.5001L17.25 13.0123L11.9983 15.7323Z"
              fill="#3C3C3B" />
        <path d="M11.9986 19.5001V15.7323L6.75 13.0123L11.9986 19.5001Z" fill="#8C8C8C" />
        <path d="M11.9985 14.8606L17.247 12.1392L11.9985 10.0465V14.8606Z" fill="#141414" />
        <path d="M6.75 12.1392L11.9986 14.8606V10.0465L6.75 12.1392Z" fill="#393939" />
      </g>
      <defs>
        <clipPath id="clip0_924_2216">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};