import { ButtonColored, Paper } from 'shared/ui';
import { LinearProgress } from '@mui/material';
import { cx } from '@emotion/css';
import { Fragment, useState } from 'react';

export const Step2 = ({ onClick }: { onClick: () => void }) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const onConfirm = () => {
    setIsConfirmed(!isConfirmed);
  };

  return (
    <Paper className="make-a-bet">
      <div className="paper__header">
        <LinearProgress variant="determinate" value={50} />
        <h2 className="paper__title title">
          <span>Откройте доступ к матчам</span>
          {/*<span className="title">*/}
          {/*    <span className="title__text">Посмотреть видео</span>*/}
          {/*    <span className="title__video-btn" />*/}
          {/*  </span>*/}
        </h2>
      </div>
      <div className="paper__body" style={{
        display: 'grid',
      }}>
        <div className="video-steps">
          <div className="video-steps__item">
            <div className="video-steps__image">
              <span className="video-steps__step">1</span>
              <img src="/step-1.png" alt="step-1" />
            </div>
            <p className="video-steps__title">Запустите Dota 2</p>
            <span className="video-steps__descr">
                Запустите клиент Dota 2, и дождитесь загрузки главного меню
              </span>
          </div>
          <div className="video-steps__item">
            <div className="video-steps__image">
              <span className="video-steps__step">2</span>
              <img src="/step-2.png" alt="step-2" />
            </div>
            <p className="video-steps__title">Зайдите в настройки</p>
            <span className="video-steps__descr">
                Зайдите в «Настройки», нажав на значок шестеренки в левом верхнем углу экрана
              </span>
          </div>
          <div className="video-steps__item">
            <div className="video-steps__image">
              <span className="video-steps__step">3</span>
              <img src="/step-3.png" alt="step-3" />
            </div>
            <p className="video-steps__title">Зайдите в Сообщество</p>
            <span className="video-steps__descr">
                Перейдите в «Сообщество», и установите флажок на пункте «Общедоступная история матчей»
              </span>
          </div>
          <div className="video-steps__item">
            <div className="video-steps__image">
              <span className="video-steps__step">4</span>
              <img src="/step-4.png" alt="step-4" />
            </div>
            <p className="video-steps__title">Подождите пару мин</p>
            <span className="video-steps__descr">
                Закройте игру и подождите пару минут, пока данные о вашем аккаунте обновятся
              </span>
          </div>
        </div>
        <ButtonColored className={cx('make-a-bet__button', {
          confirmed: isConfirmed,
        })} onClick={onConfirm}>
          {!isConfirmed ? (
            <Fragment>
              <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M31.75 10.2812C31.3 10.2812 31 10.5812 31 11.0312V14.9312C28.9 11.1812 24.85 8.78125 20.5 8.78125C14.2 8.78125 8.95 13.7313 8.5 20.0312C8.5 20.3313 8.8 20.7812 9.25 20.7812C9.7 20.7812 10 20.4812 10 20.0312C10.45 14.6313 14.95 10.2812 20.5 10.2812C24.55 10.2812 28.3 12.6812 29.95 16.2812H25.75C25.3 16.2812 25 16.5813 25 17.0312C25 17.4812 25.3 17.7812 25.75 17.7812H31.75C32.2 17.7812 32.5 17.4812 32.5 17.0312V11.0312C32.5 10.5812 32.2 10.2812 31.75 10.2812Z"
                  fill="white" />
                <path
                  d="M31.75 20.7812C31.3 20.7812 31 21.0812 31 21.5312C30.7 27.0813 26.05 31.2812 20.5 31.2812C16.45 31.2812 12.7 28.8813 11.05 25.2812H15.25C15.7 25.2812 16 24.9812 16 24.5312C16 24.0812 15.7 23.7812 15.25 23.7812H9.25C8.8 23.7812 8.5 24.0812 8.5 24.5312V30.5312C8.5 30.9812 8.8 31.2812 9.25 31.2812C9.7 31.2812 10 30.9812 10 30.5312V26.6312C12.1 30.3812 16.15 32.7812 20.5 32.7812C26.8 32.7812 32.05 27.8312 32.5 21.5312C32.5 21.2312 32.2 20.7812 31.75 20.7812Z"
                  fill="white" />
              </svg>
              Я обновил настройки в Dota 2
            </Fragment>
          ) : (
            <Fragment>
              <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M20.5001 32.7812C13.8832 32.7812 8.5 27.398 8.5 20.7814C8.5 14.1645 13.8832 8.78125 20.5001 8.78125C27.1168 8.78125 32.4998 14.1645 32.4998 20.7814C32.4998 27.398 27.1168 32.7812 20.5001 32.7812ZM20.5001 10.4282C14.7913 10.4282 10.1468 15.0728 10.1468 20.7814C10.1468 26.4902 14.7913 31.1345 20.5001 31.1345C26.2087 31.1345 30.853 26.4902 30.853 20.7814C30.853 15.0728 26.2087 10.4282 20.5001 10.4282Z"
                      fill="#00E484" />
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M17.7863 27.3999C17.422 27.3999 17.0731 27.2553 16.8159 26.998L11.7513 21.9335C11.2153 21.3979 11.2153 20.5287 11.7513 19.9931C12.2873 19.4567 13.1557 19.4567 13.6917 19.9931L17.7863 24.0868L27.3081 14.5651C27.8433 14.0291 28.7125 14.0291 29.2485 14.5651C29.7845 15.1007 29.7845 15.9699 29.2485 16.5055L18.7563 26.998C18.4991 27.2553 18.1501 27.3999 17.7863 27.3999Z"
                      fill="#00E484" />
              </svg>
              Обновление принято
            </Fragment>
          )}

        </ButtonColored>
      </div>
      <div className="paper__footer">
        <ButtonColored className="max-w-full" text="Далее" onClick={onClick} />
      </div>
    </Paper>
  );
};