import { cx } from '@emotion/css';
import { ButtonClose } from 'shared/ui';
import { detectBrowser } from 'app/detect-browser';
import './style.scss';
import { useState } from 'react';

export const BrowserAlert = () => {
  const browser = detectBrowser();

  const [visible, setVisible] = useState(browser !== 'Chrome');

  return (
    <div className={cx('browser-alert', {
      visible,
    })}>
      <img src="/chrome.svg" alt="chrome.svg" />
      <p>Для корректной работы с приложением требуется использовать браузер Chrome </p>
      <ButtonClose onClick={() => setVisible(false)} />
    </div>
  );
};