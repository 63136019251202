import './styles.scss';
import { BetItem, ButtonColored, Paper, Spinner } from 'shared/ui';
import { Fragment } from 'react';
import { useQuery } from '@tanstack/react-query';
import type { Bet } from 'typings/bet';
import { SITE_URL } from 'app/site-url';

type QueryData = {
  code: number
  count: number
  next: number | null
  previous: number | null
  data: Bet[]
}

export const BetsHistoryPage = () => {
  const { data } = useQuery<QueryData>({
    queryKey: ['bets-history'],
    queryFn: () => fetch(`${SITE_URL}/bet/`).then((res) => res.json()),
  });

  return (
    <Paper className="bets-history">
      {!data
        ? <Spinner />
        : (
          <Fragment>
            <div className="paper__header">
              <h2 className="paper__title title">
                <span>История ставок</span>
              </h2>
            </div>
            <div className="paper__body">
              {
                Array.isArray(data.data) && data.data.length
                  ? data.data.map((bet) => <BetItem key={bet.id} {...bet} />)
                  : (
                    <div className="alert" style={{ width: '100%', height: '100%' }}>
                      <div className="alert__icon">
                        <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="0.5" width="72" height="72" rx="24" fill="white" fillOpacity="0.04" />
                          <g opacity="0.36">
                            <path d="M36.5 30V36" stroke="white" strokeWidth="2" strokeLinecap="round"
                                  strokeLinejoin="round" />
                            <path d="M41.7 39L36.5 36" stroke="white" strokeWidth="2" strokeLinecap="round"
                                  strokeLinejoin="round" />
                            <path d="M29.475 32.4624H24.475V27.4624" stroke="white" strokeWidth="2"
                                  strokeLinecap="round" strokeLinejoin="round" />
                            <path
                              d="M28.725 43.775C30.263 45.3143 32.223 46.3628 34.357 46.788C36.4911 47.2132 38.7033 46.9958 40.7138 46.1635C42.7243 45.3312 44.4428 43.9213 45.6519 42.1122C46.861 40.3031 47.5063 38.176 47.5063 36C47.5063 33.824 46.861 31.6969 45.6519 29.8878C44.4428 28.0787 42.7243 26.6688 40.7138 25.8365C38.7033 25.0042 36.4911 24.7869 34.357 25.212C32.223 25.6372 30.263 26.6857 28.725 28.225L24.475 32.4625"
                              stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </g>
                        </svg>
                      </div>
                      <div className="alert__text">Здесь будет сохраняться <br />вся история ваших ставок</div>
                    </div>
                  )
              }
            </div>
            {!!data.next && (
              <div className="paper__footer">
                <ButtonColored className="max-w-full" text="Загрузить еще" disabled />
              </div>
            )}
          </Fragment>
        )}
    </Paper>
  );
};