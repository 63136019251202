import { compose } from '@reduxjs/toolkit';
import type { FunctionComponent } from 'react';

import { withAuth } from 'app/providers/with-auth';
import { withQueryClient } from 'app/providers/with-query-client';
import { withAlert } from 'app/providers/with-alert';
// import { withUser } from 'app/providers/with-user';
import { withWagmi } from 'app/providers/with-wagmi';
import { withCrypto } from 'app/providers/with-crypto';

export const withProviders = compose<FunctionComponent>(
  withWagmi,
  withQueryClient,
  withAuth,
  // withUser,
  withCrypto,
  withAlert,
);
