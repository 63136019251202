import { Fragment } from 'react';
import { Connector, useAccount, useConnect } from 'wagmi';
import { Spinner } from 'shared/ui';
import { Wallet } from 'entities';
import { AddMetamask } from 'features';

export const UserWallets = ({ showAddButton }: { showAddButton: boolean }) => {
  const { connectors, connect, isPending } = useConnect();
  const { isConnected, address } = useAccount();

  const onClick = () => {
    const connector = connectors[0] as Connector;
    isConnected ? connector.connect() : connect({ connector });
  };

  return (
    <Fragment>
      {isPending && <Spinner />}
      {address &&        (
          <Wallet
            key={address}
            address={address}
            selected
          />
        )}
      {showAddButton && <AddMetamask onClick={onClick} />}
    </Fragment>
  );
};