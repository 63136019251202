import './styles.scss';
import { SidebarAccount, SidebarBalance, SidebarGameMatch, SidebarMenu } from './ui';
import { useUserContext } from 'shared/hooks';
import { cx } from '@emotion/css';
import { useState } from 'react';

export const Sidebar = ({ onDownloadAppClick }: { onDownloadAppClick: () => void }) => {
  const { bet } = useUserContext();
  const [isSmallSidebar, setIsSmallSidebar] = useState(window.innerHeight < 800);

  const onMouseOver = () => {
    setIsSmallSidebar(false);
  };

  const onMouseOut = () => {
    setIsSmallSidebar(window.innerHeight < 800);
  };

  return (
    <aside
      className={cx('sidebar', {
        'sidebar__small': isSmallSidebar,
      })}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <div className="sidebar__logo">
        {isSmallSidebar
          ? <img src="/zeroone-small.svg" alt="zeroone" />
          : <img src="/zeroone.svg" alt="zeroone" />
        }
      </div>
      <SidebarBalance isSmallSidebar={isSmallSidebar} />
      <SidebarMenu onDownloadAppClick={onDownloadAppClick} />
      <div className="sidebar__account-game">
        {bet && <SidebarGameMatch isSmallSidebar={isSmallSidebar} />}
        <SidebarAccount />
      </div>
    </aside>
  );
};