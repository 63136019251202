import './styles.scss';
import { format } from 'date-fns';
import { useCryptoContext, useUserContext } from 'shared/hooks';

export const SidebarGameMatch = ({ isSmallSidebar }: { isSmallSidebar: boolean }) => {
  const { bet } = useUserContext();
  const { currency } = useCryptoContext();
  const { price, coefficient, created_at } = bet;

  return (
    <div className={`sidebar__game-match game-match ${isSmallSidebar ? 'game-match_small' : ''}`}>
      <div className="game-match__info">
        <div className="game-match__logo game-match__logo_dota" />
        {isSmallSidebar && <span className="game-match__status" />}
        <div className="game-match__sub-info">
          <div className="game-match__name">
            Dota
            <span className="game-match__status" />
          </div>
          <div className="game-match__countdown">
            {format(created_at, 'H:mm dd.MM.yy')}
          </div>
        </div>
      </div>
      <div className="game-match__input">
        <span>{+price} {currency}</span>
        <span>{coefficient}x</span>
      </div>
      {/*<ButtonColored className="game-match__button" text="Повысить коэффициент" />*/}
    </div>
  );
};