import './styles.scss';
import type { FunctionComponent, HTMLAttributes } from 'react';
import { cx } from '@emotion/css';

export const Paper: FunctionComponent<HTMLAttributes<HTMLDivElement>> = ({ children, className }) => {
  return (
    <div className={cx('paper', className)}>
      <svg className="paper__svg" width="1012" height="871" viewBox="0 0 1012 871" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_f_894_2399)">
          <path
            d="M390.54 210.146C289.797 95.509 293.722 -4.37164 308.277 -39.9823C393.63 -189.701 454.763 -87.772 561.326 -63.7952C667.89 -39.8183 740.127 396.864 700.913 508.236C661.699 619.608 567.557 567.542 541.988 464.391C516.42 361.24 516.468 353.443 390.54 210.146Z"
            fill="#FF742F" fillOpacity="0.24" />
        </g>
        <defs>
          <filter id="filter0_f_894_2399" x="0.584961" y="-419.956" width="1011.14" height="1290.59"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="150" result="effect1_foregroundBlur_894_2399" />
          </filter>
        </defs>
      </svg>
      {children}
    </div>
  );
};

export const PaperBordered: FunctionComponent<HTMLAttributes<HTMLDivElement>> = ({ children, className }) => {
  return (
    <div className={cx('paper paper_bordered', className)}>
      {children}
    </div>
  );
};
