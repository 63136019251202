import './styles.scss';
import { ButtonColored, ButtonDark, Paper, Spinner } from 'shared/ui';
import { SITE_URL } from 'app/site-url';
import { Connector, useAccount, useConnect, useSendTransaction } from 'wagmi';
import { postData } from 'shared/lib';
import { parseEther } from 'viem';
import { useAlertContext, useCryptoContext } from 'shared/hooks';
import { ChangeEvent, Fragment, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import type { Payment } from 'typings/payment';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { PaymentItem } from './ui/payment-item';
import { AddMetamask } from 'features';
import { WithdrawDialog } from 'widgets';
import { cx } from '@emotion/css';

const to = process.env.REACT_APP_SERVICE_WALLET;

export const PaymentPage = () => {
    const { usdRate, currency } = useCryptoContext();
    const { data: hash, isPending, sendTransaction } = useSendTransaction();
    const { onError, onSuccess } = useAlertContext();

    const { connectors, connect } = useConnect();
    const { isConnected } = useAccount();

    const [convertedAmount, setConvertedAmount] = useState(0);
    const [isWithdrawDialogOpen, setIsWithdrawDialogOpen] = useState(false);

    const ref = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();

    const onAddMetamask = () => {
      const connector = connectors[0] as Connector;
      isConnected ? connector.connect() : connect({ connector });
    };

    const { data: payments } = useQuery<{ data: Payment[] }>({
      queryKey: ['payments'],
      queryFn: () => fetch(`${SITE_URL}/payment/`).then((res) => res.json()),
    });

    useEffect(() => {
      ref.current?.focus();
    }, []);

    useEffect(() => {
      hash && onPaymentSuccess(hash);
    }, [hash]);

    const onClick = () => {
      // @ts-ignore
      if (ref.current) {
        !convertedAmount && ref.current?.focus();
        // @ts-ignore
        !!convertedAmount && sendTransaction({ to, value: parseEther(ref.current.value) });
      }
    };

  const onWithdrawClick = () => {
    setIsWithdrawDialogOpen(true)
  };


  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (ref.current) {
        ref.current.value = e.target.value;
        setConvertedAmount(+(usdRate * +ref.current.value).toFixed(4));
      }
    };

    const onPaymentSuccess = async (hash: `0x${string}`) => {
      const response = hash && await postData(`${SITE_URL}/payment/`, { hash_transaction: hash });

      if (response.code !== 201) {
        onError(response.message as string);
        return;
      }

      onSuccess('Транзакция успешна');
      setTimeout(() => navigate(0), 1000);
    };

    return (
     <>
       <div className={cx("app-download-modal__wrapper", {
         open: isPending
       })}></div>
       <Paper className="payments">
         <div className="paper__header">
           <h2 className="paper__title title">
             <span>Баланс</span>
           </h2>
         </div>
         <div className="paper__body">
           <div className="balance">
             <div className="balance__block">
               <p className="balance__header">Сумма пополнения</p>
               <div className="balance__body body">
                 <div>
                  <span className="body__text-main">
                    <input ref={ref} type="number" onChange={onChange} placeholder="0" step="0.0001" />
                    <sub>{currency}</sub>
                  </span>
                   <span className="body__text-sub">$ {convertedAmount}</span>
                 </div>
                 <div style={{ display: 'flex', columnGap: '16px', width: '100%', justifyContent: 'flex-end' }}>
                   {isConnected
                     ? (
                       <Fragment>
                         <ButtonDark style={{ width: '125px' }} text="Вывести" onClick={onWithdrawClick} disabled={isWithdrawDialogOpen} />
                         <ButtonColored style={{ width: '125px' }} text="Пополнить" onClick={onClick}
                                        disabled={isPending} />
                       </Fragment>
                     ) : <AddMetamask style={{ height: 48 }} onClick={onAddMetamask} />
                   }
                 </div>
               </div>
             </div>
           </div>
           <div className="payments__history">
             <h4 className="payments__title">история платежей</h4>
             <div className="payments__list">
               {payments && Array.isArray(payments.data)
                 ? _.reverse(payments.data.map((item) => <PaymentItem key={item.tx_hash} {...item} />))
                 : <Spinner />
               }
             </div>
           </div>
         </div>
       </Paper>
       <WithdrawDialog isOpen={isWithdrawDialogOpen} setIsOpen={setIsWithdrawDialogOpen} />
     </>
    );
  }
;