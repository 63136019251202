import './styles.scss';
import type { FunctionComponent, HTMLAttributes } from 'react';
import { ButtonDark } from 'shared/ui';

export const AddMetamask: FunctionComponent<HTMLAttributes<HTMLButtonElement>> = (props) => {
  return (
    <ButtonDark className="button-add-metamask" onClick={props.onClick} style={props.style}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M24.4389 20.5678H23.8853C23.2808 20.5678 22.7888 20.0907 22.7888 19.4965V18.9556C22.7888 18.365 23.2771 17.8844 23.8853 17.8844H24.4389C25.0434 17.8844 25.5354 18.3615 25.5354 18.9556V19.4965C25.5354 20.0907 25.0434 20.5678 24.4389 20.5678Z"
          fill="white" />
        <path d="M7.10116 5.38439L21.5618 1.62455L21.609 1.61568L20.5489 0L3.75024 5.38424L7.10116 5.38439Z"
              fill="white" />
        <path
          d="M24.3463 5.38435H27.2036L24.9308 1.62451L22.5383 2.24702L22.4639 2.27008L10.4648 5.38439L24.3463 5.38435Z"
          fill="white" />
        <path
          d="M28.1715 6.72009H3.82121C2.94805 6.72009 2.14385 7.01095 1.50305 7.49157L1.47038 7.50043H1.49398C0.58815 8.18678 0.0018153 9.25268 0.0018153 10.4569L0 28.2631C0 30.324 1.71546 32 3.82486 32H28.1751C30.2845 32 32 30.324 32 28.2631V10.4605C31.9946 8.39436 30.2809 6.72009 28.1715 6.72009ZM30.3916 21.7473H21.5145C21.1188 21.7473 20.803 21.4334 20.803 21.0521V17.7197C20.803 17.333 21.1243 17.0245 21.5145 17.0245H30.3916V21.7473ZM21.5164 15.455C20.2384 15.455 19.2019 16.4677 19.2019 17.7163V21.0487C19.2019 22.2972 20.2384 23.3099 21.5164 23.3099H30.3934V28.2579C30.3934 29.4568 29.395 30.4322 28.1679 30.4322H3.81758C2.59045 30.4322 1.592 29.4568 1.592 28.2579L1.58837 10.4607C1.58837 9.26179 2.58677 8.28633 3.81395 8.28633H28.1642C29.3914 8.28633 30.3898 9.26175 30.3898 10.4607V15.4604H21.5165L21.5164 15.455Z"
          fill="white" />
      </svg>
      Добавить кошелек
    </ButtonDark>
  );
};