import './styles/main.scss';
import { ru } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';
import { withProviders } from 'app/providers';
import { Alert, BrowserAlertAuth, Layout } from 'widgets';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  AuthPage,
  BetsHistoryPage,
  CurrentBetPage,
  MakeABetPage,
  OtherBetsPage,
  PaymentPage,
  ProfilePage,
} from 'pages';
import { RoutesPath } from 'shared/routes-path';

setDefaultOptions({ locale: ru });

export const App = withProviders(() => {
  return (
    <div className="app">
      <BrowserAlertAuth />
      <Pages />
      <Alert />
    </div>
  );
});

const Pages = () => {
  return (
    <Routes>
      <Route path={RoutesPath.AUTH} element={<AuthPage />} />
      <Route element={<Layout />}>
        <Route path={RoutesPath.PROFILE} element={<ProfilePage />} />
        <Route path={RoutesPath.PAYMENTS} element={<PaymentPage />} />
        <Route path={RoutesPath.MAKE_A_BET} element={<MakeABetPage />} />
        <Route path={RoutesPath.BETS_HISTORY} element={<BetsHistoryPage />} />
        <Route path={RoutesPath.CURRENT_BET} element={<CurrentBetPage />} />
        <Route path={RoutesPath.OTHER_BETS} element={<OtherBetsPage />} />
      </Route>
      <Route path="*" element={<Navigate replace to={RoutesPath.PROFILE} />} />
    </Routes>
  );
};
