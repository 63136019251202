import type { FunctionComponent } from 'react';
import { config } from 'app/config';
import { WagmiProvider } from 'wagmi';


export const withWagmi = (Component: FunctionComponent) => () => {
  return (
    <WagmiProvider config={config}>
      <Component />
    </WagmiProvider>
  );
};
