import { ChangeEvent, forwardRef, useImperativeHandle, useRef } from 'react';
import type { InputProps } from 'shared/ui/input';
import { cx } from '@emotion/css';

type Input = InputProps & { currency: string, max: number }

export const InputWithCurrency = forwardRef<HTMLInputElement, Input>((props, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);

  // @ts-ignore
  useImperativeHandle(ref, () => {
    return {
      value: inputRef.current?.value,
      focus() {
        inputRef.current?.focus();
      },
    };
  }, []);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    ref.current.value = event.target.value
  };

  return (
    <div className={cx('input-with-label input-with-label_currency', props.className, {
      disabled: props.disabled,
    })}>
      <label htmlFor={props.id}>{props.label}</label>
      <div>
        <input
          ref={inputRef}
          id={props.id}
          type="number"
          min="0"
          max={props.max}
          placeholder={props.placeholder} required
          defaultValue={props.defaultValue}
          step="0.0001"
          disabled={props.disabled}
          onChange={onChange}
        />
        <span>{props.currency}</span>
      </div>
    </div>
  );
});

InputWithCurrency.displayName = 'InputWithCurrency';
