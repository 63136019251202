import './styles.scss';
import { ButtonClose, ButtonColored, ButtonDark } from 'shared/ui';
import { cx } from '@emotion/css';
import type { DispatchedStateAction } from 'shared/typings';
import { useMutation } from '@tanstack/react-query';
import { SITE_URL } from 'app/site-url';
import type { MouseEvent } from 'react';

type AppDownloadModalProps = {
  open: boolean;
  setIsOpen: DispatchedStateAction<boolean>;
};

export const AppDownloadModal = ({ open, setIsOpen }: AppDownloadModalProps) => {
  const onClick = () => {
    setIsOpen(false);
  };

  const onDownload = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onDownloadMutation.mutate();
  };

  const onDownloadMutation = useMutation({
    mutationKey: ['download'],
    mutationFn: async () => {
      const response = await fetch(SITE_URL + '/download/app/', {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Failed to download file');
      }

      const { file: fileUrl } = await response.json();

      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = fileUrl.split('/').pop(); // Use the file name from the URL
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    onError: (error) => {
      console.error('Error downloading file:', error);
      alert('Failed to download the file. Please try again.');
    },
  });

  
  return (
    <div className={cx('app-download-modal__wrapper', { open })} onClick={onClick}>
      <div className="app-download-modal__bg" />
      <div className="app-download-modal" onClick={(e) => e.stopPropagation()}>
        <ButtonClose onClick={onClick} />
        <div className="app-download-modal__close" />
        <div className="app-download-modal__icon">
          <svg width="61" height="66" viewBox="0 0 61 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M30.4426 31.3706C30.8404 31.3706 31.222 31.2126 31.5033 30.9313C31.7846 30.65 31.9426 30.2684 31.9426 29.8706V5.19062L35.2726 8.55062C35.4121 8.69296 35.5786 8.80606 35.7623 8.88332C35.946 8.96058 36.1433 9.00046 36.3426 9.00063C36.546 9.00409 36.748 8.96696 36.9369 8.89141C37.1258 8.81586 37.2977 8.70341 37.4426 8.56062C37.7211 8.2781 37.8772 7.89733 37.8772 7.50063C37.8772 7.10392 37.7211 6.72315 37.4426 6.44062L31.7126 0.660626C31.5747 0.451523 31.3856 0.281132 31.1634 0.165623C30.9411 0.0501136 30.693 -0.00668176 30.4426 0.000625779C30.0456 0.00419357 29.6655 0.161971 29.3826 0.440624L23.4426 6.27063C23.1601 6.55176 23.0009 6.93357 23 7.33209C22.9991 7.7306 23.1565 8.11317 23.4376 8.39563C23.7187 8.67808 24.1006 8.83729 24.4991 8.83823C24.8976 8.83916 25.2801 8.68175 25.5626 8.40062L28.9426 5.07063V29.8706C28.9426 30.2684 29.1006 30.65 29.3819 30.9313C29.6632 31.2126 30.0448 31.3706 30.4426 31.3706Z"
              fill="white" />
            <path
              d="M50.7266 8H45.4198C44.7989 8 44.2034 8.24666 43.7643 8.68573C43.3252 9.12479 43.0786 9.7203 43.0786 10.3412C43.0786 10.9622 43.3252 11.5577 43.7643 11.9967C44.2034 12.4358 44.7989 12.6825 45.4198 12.6825H50.7266C52.1754 12.6825 53.5649 13.258 54.5894 14.2825C55.6139 15.307 56.1895 16.6965 56.1895 18.1453V43.6335C56.1853 45.0796 55.6079 46.4651 54.5839 47.4862C53.5599 48.5073 52.1727 49.0807 50.7266 49.0807H10.1453C8.69648 49.0807 7.30698 48.5052 6.28249 47.4807C5.25801 46.4562 4.68245 45.0667 4.68245 43.6179V18.1453C4.68245 16.6965 5.25801 15.307 6.28249 14.2825C7.30698 13.258 8.69648 12.6825 10.1453 12.6825H15.8423C16.4632 12.6825 17.0587 12.4358 17.4978 11.9967C17.9369 11.5577 18.1835 10.9622 18.1835 10.3412C18.1835 9.7203 17.9369 9.12479 17.4978 8.68573C17.0587 8.24666 16.4632 8 15.8423 8H10.1453C7.45588 8.00413 4.87778 9.07433 2.97605 10.9761C1.07433 12.8778 0.00412808 15.4559 0 18.1453V43.6335C0.00825105 46.3202 1.08027 48.8943 2.98155 50.7927C4.88283 52.691 7.45858 53.7591 10.1453 53.7632H28.0947V61.3175H16.3886C15.7677 61.3175 15.1722 61.5642 14.7331 62.0033C14.294 62.4423 14.0474 63.0378 14.0474 63.6588C14.0474 64.2797 14.294 64.8752 14.7331 65.3143C15.1722 65.7533 15.7677 66 16.3886 66H44.4833C45.1043 66 45.6998 65.7533 46.1388 65.3143C46.5779 64.8752 46.8245 64.2797 46.8245 63.6588C46.8245 63.0378 46.5779 62.4423 46.1388 62.0033C45.6998 61.5642 45.1043 61.3175 44.4833 61.3175H32.7772V53.7632H50.7266C53.416 53.7591 55.9941 52.6889 57.8959 50.7871C59.7976 48.8854 60.8678 46.3073 60.8719 43.6179V18.1453C60.8678 15.4559 59.7976 12.8778 57.8959 10.9761C55.9941 9.07433 53.416 8.00413 50.7266 8Z"
              fill="white" />
          </svg>
        </div>
        <div className="app-download-modal__heading">
          Для верификации результатов игры <br /> Вам потребуется установит ZeroOne <br /> приложение.
        </div>
        <div className="app-download-modal__actions actions">
          <ButtonDark text="Уже установлено" onClick={onClick} />
          <ButtonColored text="Установить" onClick={onDownload} disabled={onDownloadMutation.isPending} />
        </div>
      </div>
    </div>
  );
};
