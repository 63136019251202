import './styles.scss';
import { useUserContext } from 'shared/hooks';
import { Link } from 'react-router-dom';

export const SidebarAccount = () => {
  const { username, id } = useUserContext();

  return (
    <Link to="/profile" className="sidebar__account account">
      <div className="account__avatar">
        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.75">
            <path
              d="M0.00136334 26.6799C0.184199 33.6228 3.05623 40.0775 8.09424 44.8621C12.9623 49.4838 19.288 52.0052 25.9793 52C26.2129 52 26.4466 52 26.6802 51.9924C33.6231 51.8095 40.0777 48.9375 44.8623 43.8995C49.6415 38.8614 52.1758 32.2643 51.9931 25.3214C51.8103 18.3785 48.9382 11.9239 43.9002 7.13926C38.8621 2.36011 32.2572 -0.166367 25.3222 0.00850555C18.3793 0.191341 11.9246 3.06337 7.13999 8.10139C2.36084 13.1395 -0.173444 19.7366 0.00922948 26.6795L0.00136334 26.6799ZM8.54865 9.43972C12.9697 4.77739 18.9422 2.11849 25.3669 1.95077C25.5802 1.95077 25.7961 1.94315 26.0094 1.94315C32.1928 1.94315 38.0489 4.27682 42.5482 8.54799C47.2105 12.9691 49.8694 18.9415 50.0371 25.3663C50.2073 31.7884 47.866 37.8934 43.4401 42.5551C39.019 47.2174 33.0466 49.8764 26.6218 50.0441C20.2124 50.2142 14.0947 47.8729 9.43295 43.447C4.77062 39.026 2.11171 33.0535 1.94399 26.6287C1.77385 20.2066 4.11514 14.1016 8.54103 9.43991L8.54865 9.43972Z"
              fill="#F3F3F3" />
            <path
              d="M26.1921 23.4296C30.3339 23.3178 33.5972 19.8795 33.4925 15.7404C33.3808 11.5986 29.9425 8.33529 25.8034 8.43995C21.6616 8.55168 18.3983 11.99 18.5029 16.1291C18.6147 20.2709 22.053 23.5342 26.1921 23.4296Z"
              fill="#F3F3F3" />
            <path
              d="M26.5181 45.8319C32.3562 45.6821 37.5364 43.0233 41.0611 38.9144C41.6274 38.2516 41.9245 37.3856 41.828 36.5222C41.1577 30.6004 36.1322 26.0039 30.0349 26.0039H21.9674C15.8703 26.0039 10.8451 30.6053 10.1742 36.5222C10.0777 37.3932 10.3748 38.2643 10.9487 38.927C14.6994 43.2948 20.3164 45.9993 26.5176 45.8368L26.5181 45.8319Z"
              fill="#F3F3F3" />
          </g>
        </svg>
      </div>
      <div className="account__info">
          <span className="account__name">
             {username.length > 10 ? username.slice(0,10) + '...'  : username}
          </span>
        <span className="account__id">ID {id}</span>
      </div>
    </Link>
  );
};