import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthContext, UserContext } from 'shared/hooks';
import { AppDownloadModal, BrowserAlert, Sidebar } from 'widgets';
import { RoutesPath } from 'shared/routes-path';
import { fromWeiToEther } from 'shared/lib';
import { useQuery } from '@tanstack/react-query';
import type { User } from 'typings/user';
import { SITE_URL } from 'app/site-url';
import { Spinner } from 'shared/ui';

export const Layout = () => {
  const navigate = useNavigate();
  const { isAuth } = useAuthContext();
  const { data } = useQuery<User>({
    queryKey: ['user', isAuth],
    queryFn: () => fetch(`${SITE_URL}/user/`).then((res) => res.json()),
  });

  const [isOpen, setIsOpen] = useState(!data?.is_app_download ?? false);

  const onClick = () => {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    if (!isAuth) {
      navigate(RoutesPath.AUTH);
    }
  }, [isAuth]);

  if (!data) return <Spinner />;


  return (
    <UserContext.Provider value={{
      ...data,
      balance: fromWeiToEther(data.balance),
    }}>
      <Sidebar onDownloadAppClick={onClick} />
      <div className="main">
        <BrowserAlert />
        <Outlet />
      </div>
      <AppDownloadModal open={isOpen} setIsOpen={setIsOpen} />
    </UserContext.Provider>

  );
};