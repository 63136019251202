import './styles.scss';
import type { FunctionComponent, HTMLAttributes } from 'react';
import { cx } from '@emotion/css';
import type { Payment } from 'typings/payment';
import { useCryptoContext } from 'shared/hooks';
import { format } from 'date-fns';
import { fromWeiToEther, shortenStringFormatter } from 'shared/lib';

type PaymentItemProps = Payment & HTMLAttributes<HTMLDivElement>

export const PaymentItem: FunctionComponent<PaymentItemProps> = (props) => {
  const { currency, usdRate } = useCryptoContext();
  const amount = fromWeiToEther(props.amount);

  return (
    <div className={cx('payment-item', props.className)}>
      <div className="payment-item__provider-icon" />
      <div className="payment-item__provider-info provider-info">
        <span className="provider-info__address" title={props.tx_hash}>{shortenStringFormatter(props.tx_hash)}</span>
        <span className="provider-info__payment-date">
          {format(props.created_at, 'd MMMM yyyy г. в H:mm:ss ')}
        </span>
      </div>
      <div className="payment-item__payment payment">
        <span className={cx('payment__crypto', {
          // 'pay-out': !props.isPayed,
        })}>
          {/*{amount ? '+' : '-'}*/}
          {amount} {currency}
        </span>
        <span className="payment__dollars">${(usdRate * amount).toFixed(4)}</span>
      </div>
    </div>
  );
};