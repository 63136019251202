import './styles.scss';
import type { FunctionComponent, HTMLAttributes } from 'react';
import { cx } from '@emotion/css';
import { ButtonClose } from 'shared/ui';
import { useBalance, useDisconnect } from 'wagmi';
import { shortenStringFormatter } from 'shared/lib';
import { useCryptoContext } from 'shared/hooks';
import { Skeleton } from '@mui/material';

type WalletProps = {
  selected?: boolean
  selectable?: boolean
  address: `0x${string}`
} & HTMLAttributes<HTMLDivElement>

export const Wallet: FunctionComponent<WalletProps> = ({ ...props }) => {
  const { currency, chainId, usdRate } = useCryptoContext();
  const { data } = useBalance({
    address: props.address,
    unit: 'ether',
    chainId,
  });

  const { disconnect } = useDisconnect();

  return (
    <div
      className={cx('wallet', props.className, {
        selectable: !props.selected,
        selected: props.selected,
      })}
      onClick={props.onClick}
    >
      <div className="wallet__provider wallet__provider_metamask" />
      <div className="wallet__address">
        {shortenStringFormatter(props.address)}
      </div>
      <div className="wallet__total">
        {data ? `$${(usdRate * +data.formatted.slice(0, 4)).toFixed(0)}` :
          <Skeleton variant="text" />}
      </div>
      <div className="wallet__total-crypto">
        {data ? (data.formatted.slice(0, 4) + ' ' + currency) : <Skeleton variant="text" />}
      </div>
      <ButtonClose onClick={() => disconnect()} disabled={!props.selected} />
    </div>
  );
};
